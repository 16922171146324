import {Box, Grid, Link, Tooltip, Typography} from "@material-ui/core";
import qs from "qs";
import {Helmet} from "react-helmet";
import PaperPanel from "../../../components/PaperPanel";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useSentinelDetailAPI, useSentinelListAPI} from "../../../hooks/useSentinelAPI";

import {faCheck, faFileExcel} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {yellow} from "@material-ui/core/colors";
import classnames from "classnames";
import React from "react";
import {useParams} from "react-router";
import {Breadcrumbs} from "../../../components/Breadcrumbs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterDate, FilterSearch, FilterSelect, FilterSwitch} from "../../../components/Filters";
import officeOptions from "../../../components/forms/choices/offices.json";
import {MuiNavLink} from "../../../components/Links";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {makeNovoClasses} from "../../../theme";
import {saveExcel} from "../../../utils/excel";

const RFPCompetitorDetail = (props) => {
  const {...rest} = props;
  const [ordering, setOrdering] = React.useState("");
  const {competitorId} = useParams();
  const novoClasses = makeNovoClasses();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(
    ["Search", "Status", "Start Date", "End Date", "Wins Only", "Office"]
    // {
    //   "Start Date": {
    //     value: moment().subtract(1, "year").format("YYYY-MM-DD"),
    //     label: moment().subtract(1, "year").format("YYYY-MM-DD"),
    //   },
    // }
  );

  const filterParams = {
    status: filterOptions.Status.value,
    q: filterOptions.Search.value,
    date_min: filterOptions["Start Date"].value,
    date_max: filterOptions["End Date"].value,
    wins_only: filterOptions["Wins Only"].value,
    rfp__office_id: filterOptions.Office.value,
  };

  const {query: competitorQuery} = useSentinelDetailAPI(`marketing/rfps/competitors/${competitorId}/`, {
    initialData: {},
  });

  const competitor = competitorQuery.data;

  const {query: bidQuery} = useSentinelListAPI(
    `marketing/rfps/competitor-bids/?page_size=10000&company_id=${competitorId}&${qs.stringify(filterParams)}`,
    {
      initialData: {results: []},
    }
  );

  const bids = bidQuery.data.results;

  return (
    <>
      <Helmet title={`RFP Competitors`} />
      <Breadcrumbs>
        <MuiNavLink href="/v2/dashboard/">
          <Typography color="textSecondary">Dashboard</Typography>
        </MuiNavLink>
        {/* <Link href="/v2/marketing/"> */}
        <Typography color="textSecondary">Marketing</Typography>
        <Typography color="textSecondary">RFPs</Typography>
        {/* </Link> */}
        <Link href="/v2/marketing/rfps/competitors/">
          <Typography color="textSecondary">Competitors</Typography>
        </Link>

        <Typography color="textPrimary">{competitor.name}</Typography>
      </Breadcrumbs>
      <PaperPanel>
        <PaperPanel.Header isLoading={competitorQuery.isFetching || bidQuery.isFetching}>
          <PaperPanel.Header.Title>{competitor.name}</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.PDFButton
                href={`/reports2/marketing/rfps/competitors/${competitorId}/?${qs.stringify(filterParams)}&order_by=${ordering}`}
                target="_blank"
              />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                onClick={() => {
                  saveExcel(
                    bids.map((bid) => {
                      return {
                        "Project Name": bid.rfp.project_name,
                        "Bid Date": bid.bid_date,
                        Fee: bid.fee ? `${bid.fee.toFixed(2)}%` : "N/A",
                        "Insurance Rate": bid.insurance_rate ? `${bid.insurance_rate.toFixed(2)}%` : "N/A",
                        "Weekly GCs": bid.weekly_gcs ? `${bid.weekly_gcs.toFixed(2)}` : "N/A",
                        "Won?": bid.winning_bid ? "Yes" : "No",
                      };
                    }),
                    `Bids for ${competitor.name}`,
                    [{wch: 50}, {wch: 15}, {wch: 15}, {wch: 15}, {wch: 15}, {wch: 15}]
                  );
                }}
                disabled={bidQuery.isFetching || bids.length < 1 || competitorQuery.isFetching}
              >
                Get Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                isFetching={competitorQuery.isFetching || bidQuery.isFetching}
                onClick={() => {
                  competitorQuery.refetch();
                  bidQuery.refetch();
                }}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <FilterSearch
                name="Search"
                label="Search"
                value={filterOptions.Search.value}
                onChange={(value) => {
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item sm={3} xs={6}>
              <FilterDate
                name="Start Date"
                value={filterOptions["Start Date"].value}
                label="Start Date"
                onChange={(date) => {
                  setFilterOption("Start Date", date, date);
                  //   setDateRange("");
                }}
              />
            </Grid>
            <Grid item sm={3} xs={6}>
              <FilterDate
                name="End Date"
                value={filterOptions["End Date"].value}
                label="End Date"
                onChange={(date) => {
                  setFilterOption("End Date", date, date);
                  //   setDateRange("");
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FilterSelect
                name="Office"
                label="Office"
                value={filterOptions.Office.value}
                options={officeOptions}
                onChange={(value, label) => {
                  setFilterOption("Office", value, label);
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FilterSwitch
                label="Only Show Wins"
                name="only_show_wins"
                checked={filterOptions["Wins Only"].value}
                onChange={(value, label) => {
                  setFilterOption("Wins Only", value, label);
                }}
              />
            </Grid>
            {/* <Box mt={1} /> */}
          </Grid>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                }}
              />
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <ReactTableMui
            showFooter={bids.length > 0}
            getRowProps={(row) => {
              return {
                style: {
                  backgroundColor: row.original.winning_bid && yellow[100],
                },
              };
            }}
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            columns={[
              {
                Header: "Project Name",
                accessor: "rfp.project_name",
                Cell: ({row, value}) => {
                  // return <>{row.original.rfp.project_name}</>;
                  return (
                    <Link underline="always" href={`/v2/marketing/rfps/${row.original.rfp.id}/`}>
                      {row.original.rfp.project_name}
                    </Link>
                  );
                },
              },
              {Header: "Bid Date", accessor: "bid_date", Footer: () => "Average"},
              {
                Header: "Fee",
                accessor: "fee",
                Cell: ({value}) => (value ? `${value.toFixed(2)}%` : "N/A"),
                Footer: () => {
                  return competitor.average_fee ? `${competitor.average_fee.toFixed(2)}%` : "N/A";
                },
              },
              {
                Header: "Insurance Rate",
                accessor: "insurance_rate",
                Cell: ({value}) => (value ? `${value.toFixed(2)}%` : "N/A"),
                Footer: () => {
                  return competitor.average_insurance_rate ? `${competitor.average_insurance_rate.toFixed(2)}%` : "N/A";
                },
              },
              {
                Header: "Weekly GCs",
                accessor: "weekly_gcs",
                Cell: ({value}) => (value ? `$${value.toFixed(2)}` : "N/A"),
                Footer: () => {
                  return competitor.average_weekly_gcs ? `$${competitor.average_weekly_gcs.toFixed(2)}` : "N/A";
                },
              },
              {
                Header: "Won",
                accessor: "winning_bid",
                Cell: ({value, row}) => {
                  return value && <FontAwesomeIcon icon={faCheck} />;
                },
                Footer: () => {
                  return (
                    <Tooltip
                      title={
                        <>
                          <Box>Wins: {competitor.total_wins}</Box>
                          <Box>Bids: {competitor.total_bids}</Box>
                        </>
                      }
                    >
                      <Box>{competitor.win_rate ? `${competitor.win_rate}%` : "N/A"}</Box>
                    </Tooltip>
                  );
                },
                sortType: "basic",
              },
            ]}
            data={bids}
            initialState={{
              sortBy: [
                {
                  id: "bid_date",
                  desc: true,
                },
              ],
            }}
            onSortBy={(sortBy) => {
              const sorting = sortBy[0];
              if (!sorting) return setOrdering("");
              const ordering = sorting.desc ? `-${sorting.id}` : sorting.id;
              setOrdering(ordering.replace(".", "__"));
            }}
            {...rest}
          />
        </PaperPanel.Body>
      </PaperPanel>
    </>
  );
};

export default RFPCompetitorDetail;
