import {Box, Grid, Link, Typography} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import LaunchIcon from "@material-ui/icons/Launch";
import {chain} from "lodash";
import NumberFormat from "react-number-format";
import {VictoryContainer, VictoryLabel, VictoryPie, VictoryTheme} from "victory";
import {Currency, Number} from "../../../components/Accounting";
import {LabelValue} from "../../../components/LabelValue";
import {PaperItem} from "../../../components/PaperItem";
import {StatusLabel} from "../../../components/Status";
import Typography2 from "../../../components/Typography2";

export const RFPChart = (props: {rfps: any[]; property: string; label: string; [rest: string]: any}) => {
  const {rfps, property, label, ...rest} = props;

  const chartData = chain(rfps)
    .countBy(property)
    .map((count, type) => ({
      x: type,
      y: Number(((count / rfps.length) * 100).toFixed(2)),
    }))
    .value();
  return (
    <>
      <Box textAlign="center">
        <Typography variant="h6">{label}</Typography>
      </Box>
      <VictoryPie
        containerComponent={<VictoryContainer title={label} responsive={true} />}
        theme={VictoryTheme.material}
        // labelIndicator
        data={chartData}
        height={300}
        // labelIndicatorOuterOffset={15}
        // labelIndicatorInnerOffset={25}
        labelComponent={
          <VictoryLabel
            textAnchor="middle"
            verticalAnchor="middle"
            text={({datum}) => [datum.x, `(${datum.y}%)`]}
            style={{fill: "black"}}
            // backgroundStyle={{fill: "rgba(255, 255, 255, 0.5)"}}
          />
        }
        sortKey={(datum) => datum.y}
        // events={[
        //   {
        //     target: "data",
        //     eventHandlers: {
        //       onClick: () => {
        //         return [
        //           {
        //             target: "data",
        //             mutation: ({style}) => {
        //               return style.fill === "#c43a31" ? null : {style: {fill: "#c43a31"}};
        //             },
        //           },
        //           {
        //             target: "labels",
        //             mutation: ({text}) => {
        //               return text === "clicked" ? null : {text: "clicked"};
        //             },
        //           },
        //         ];
        //       },
        //     },
        //   },
        // ]}
        {...rest}
      />
    </>
  );
};

export const CompetitorBidPaperItem = (props: {
  competitorBid: {
    company: {name: string; url: string};
    weekly_gcs: number;
    insurance_rate: number;
    fee: number;
    notes: string;
  };
  onEdit: () => void;
}) => {
  const {competitorBid, onEdit, ...rest} = props;
  return (
    <PaperItem>
      <PaperItem.Body>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={3}>
            <Link href={competitorBid.company.url} underline="always">
              {competitorBid.company.name}
            </Link>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography2 type="metadata">Weekly GCs:</Typography2> <Currency number={competitorBid.weekly_gcs} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography2 type="metadata">Insurance:</Typography2>{" "}
            <NumberFormat value={competitorBid.insurance_rate} displayType={"text"} suffix={"%"} decimalScale={3} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography2 type="metadata">Fee:</Typography2>{" "}
            <NumberFormat value={competitorBid.fee} displayType={"text"} suffix={"%"} decimalScale={3} />
          </Grid>
          {competitorBid.notes && (
            <Grid item xs={12} sm={6}>
              <Typography2 type="metadata">Notes:</Typography2> {competitorBid.notes}
            </Grid>
          )}
        </Grid>
      </PaperItem.Body>
      <PaperItem.RightHover>
        <PaperItem.RightHover.IconButton icon={EditIcon} title="Edit" onClick={onEdit} />
      </PaperItem.RightHover>
    </PaperItem>
  );
};

export const WeeklyLogRFPPaperItem = (props) => {
  const {rfp, isSelected, addSelectedId, removeSelectedId, onClickEdit, ...rest} = props;
  return (
    <PaperItem>
      <PaperItem.Left>
        <PaperItem.SelectedCheckbox
          checked={isSelected}
          onChange={(e, selectBetween) => {
            if (selectBetween) {
              addSelectedId(rfp.id);
            } else {
              removeSelectedId(rfp.id);
            }
          }}
        />
      </PaperItem.Left>
      <PaperItem.Body>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={3}>
            <StatusLabel status={rfp.status_display} /> {rfp.project_name}
          </Grid>

          <Grid item xs={12} sm={3}>
            <LabelValue label="Due" value={rfp.due_date} />
          </Grid>
          <Grid item xs={12} sm={3}>
            {rfp.address_display}
          </Grid>
          <Grid item xs={12} sm={3}>
            <LabelValue label="SqFt" value={<Number number={rfp.square_footage} />} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <LabelValue label="Budget" value={<Currency number={rfp.anticipated_budget} />} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <LabelValue label="Rep/CM" value={rfp?.construction_manager?.name} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <LabelValue
              label="Team"
              value={[rfp.project_executive, rfp.project_manager, rfp.superintendent]
                .filter((member) => member)
                .map((teamMember) => {
                  if (teamMember) {
                    return teamMember.last_name;
                  }
                })
                .join(", ")}
            />
          </Grid>
          <Grid item xs={6}>
            <LabelValue label="Interview/Prep Status" value={rfp.interview_prep_status} />
          </Grid>
          <Grid item xs={6}>
            {rfp.result && <LabelValue label="Result" value={rfp.result} />}
          </Grid>
        </Grid>
      </PaperItem.Body>

      <PaperItem.RightHover>
        <PaperItem.RightHover.IconButton icon={EditIcon} title="Quick Edit" onClick={onClickEdit} />
        <PaperItem.RightHover.IconButton
          icon={LaunchIcon}
          title="Detail"
          href={rfp.url}
          component={Link}
          target="_blank"
        />
      </PaperItem.RightHover>
    </PaperItem>
  );
};
