import {Dialog, DialogContent, DialogContentText, DialogTitle, Link, Typography} from "@material-ui/core";
import React from "react";
import {Helmet} from "react-helmet";
import {axiosAPI} from "../../../api";
import {Breadcrumbs} from "../../../components/Breadcrumbs";
import Wizard from "../../../components/forms/Wizard";
import {MuiNavLink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import useBlockUI from "../../../hooks/useBlockUI";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {RFPWizardBoxFields, RFPWizardCompetitorFields, RFPWizardOnboardingFields} from "./RFPForms";

const RFPOnboarding = (props) => {
  const {...rest} = props;
  const [createdRFP, setCreatedRFP] = React.useState(null);
  const [showSuccessDialog, setShowSuccessDialog] = React.useState(false);
  const [timeUntilClose, setTimeUntilClose] = React.useState(5);
  const blockUI = useBlockUI();

  const {query: tagQuery} = useSentinelListAPI(`marketing/rfps/tags/`, {
    initialData: [],
  });
  const tagOptions = tagQuery.data;

  const createRFP = async (values, competitorBids) => {
    blockUI.blockUI("Saving...");
    tagQuery.refetch();
    return axiosAPI
      .post("marketing/rfps/", {tags: [], competitor_bids: [], status: "active", ...values})
      .then((response) => {
        setCreatedRFP(response.data);
        if (!competitorBids || competitorBids.length === 0) {
          blockUI.unblockUI();
          return;
        }
        Promise.all(
          competitorBids
            .filter((competitorBid) => competitorBid)
            .map((competitorBid) => {
              return axiosAPI.post(`marketing/rfps/${response.data.id}/competitor-bids/`, {
                ...competitorBid,
                rfp: response.data,
              });
            })
        ).then(() => {
          blockUI.unblockUI();
        });
      });
  };

  const startCountdown = () => {
    const interval = setInterval(() => {
      setTimeUntilClose((time) => {
        if (time <= 1) {
          clearInterval(interval);
          window.location.href = "/";
          return 0;
        }
        return time - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  };

  return (
    <>
      <Helmet title="RFP Onboarding" />
      <Breadcrumbs>
        <MuiNavLink href="/v2/dashboard/">
          <Typography color="textSecondary">Dashboard</Typography>
        </MuiNavLink>
        {/* <Link href="/v2/marketing/"> */}
        <Typography color="textSecondary">Marketing</Typography>
        {/* </Link> */}
        {/* <Link href="/v2/marketing/rfps/"> */}
        <Typography color="textSecondary">RFPs</Typography>
        {/* </Link> */}
        <Typography color="textPrimary">RFP Onboarding</Typography>
      </Breadcrumbs>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>RFP Onboarding</PaperPanel.Header.Title>
        </PaperPanel.Header>
        <PaperPanel.Body p={1}>
          <Wizard
            onSubmit={(values) => {
              // window.location.href = "/";
              setShowSuccessDialog(true);
              startCountdown();
            }}
            // initialValues={{
            //   status: "active",
            //   tags: [],
            //   competitor_bids: [],
            // }}
            submitButtonLabel="Submit to Marketing"
          >
            <Wizard.Page title="General Information">
              <RFPWizardOnboardingFields tagOptions={tagOptions.map((tag) => tag.label)} />
            </Wizard.Page>
            <Wizard.Page
              title="Competitors"
              onAdvance={(values, form) => {
                createRFP(values, form.getFieldState("competitor_bids").value);
              }}
            >
              <RFPWizardCompetitorFields />
            </Wizard.Page>
            <Wizard.Page title="Uploads">
              <RFPWizardBoxFields rfp={createdRFP} />
            </Wizard.Page>
          </Wizard>
          {/* <RFPOnboardingForm
            onSubmit={(values) => {
              blockUI.blockUI("Saving...");
              tagQuery.refetch();
              axiosAPI.post("marketing/rfps/", values).then((response) => {
                window.location.href = response.data.url;
                blockUI.unblockUI();
              });
            }}
            tagOptions={tagOptions.map((tag) => tag.label)}
            isFetching={false}
            initialValues={{
              status: "active",
            }}
            isNew
          /> */}
        </PaperPanel.Body>
      </PaperPanel>
      <Dialog open={showSuccessDialog} onClose={() => {}}>
        <DialogTitle>RFP Created</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Thank you for completing the RFP onboarding form. Your RFP has been created and you may close this window.
          </DialogContentText>
          <DialogContentText>You will be redirected to Sentinel in {timeUntilClose} seconds.</DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RFPOnboarding;
