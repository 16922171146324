import {faFileExcel} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Grid, Typography} from "@material-ui/core";
import {uniq} from "lodash";
import qs from "qs";
import React from "react";
import {Helmet} from "react-helmet";
import {useSelectIds} from "../../../../js/hooks";
import {Breadcrumbs} from "../../../components/Breadcrumbs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterSelect} from "../../../components/Filters";
import BlockUI from "../../../components/GlobalLoaders";
import {MuiNavLink} from "../../../components/Links";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import officeChoices from "../../../components/forms/choices/offices.json";
import statusChoices from "../../../components/forms/choices/rfpStatusTypes.json";
import weeklyLogProgressChoices from "../../../components/forms/choices/rfpWeeklyLogProgress.json";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {saveExcel} from "../../../utils/excel";
import {WeeklyLogRFPPaperItem} from "./RFPComponents";
import {RFPNotesQuickEditDialog} from "./RFPForms";

const RFPWeeklyLog = (props) => {
  const {...rest} = props;

  const [editDialogIsOpen, setEditDialogIsOpen] = React.useState(false);
  const [activeRFP, setActiveRFP] = React.useState(null);
  const [initialFetched, setInitialFetched] = React.useState(false);

  const blockUI = useBlockUI();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions([
    "Progress",
    "Office",
    "Status",
  ]);

  const filterParams = {
    progress: filterOptions.Progress.value,
    office: filterOptions.Office.value,
    status: filterOptions.Status.value,
  };

  const {
    query: rfpQuery,
    create: createRFP,
    update: updateRFP,
    delete: deleteRFP,
  } = useSentinelListAPI(`marketing/rfps/?page_size=10000&${qs.stringify(filterParams)}`, {
    initialData: {
      results: [],
    },
  });

  const rfps = rfpQuery.data.results;

  const allRFPIds = rfps.map((rfp) => rfp.id);

  const {
    selectedIds,
    addSelectedId,
    addSelectedIds,
    removeSelectedId,
    removeSelectedIds,
    addAllSelectedIds,
    removeAllSelectedIds,
    allIdsSelected,
  } = useSelectIds(allRFPIds);

  const officesInQuery = uniq(rfps.map((rfp) => rfp.office_display));

  React.useEffect(() => {
    if (rfpQuery.isFetchedAfterMount) {
      addAllSelectedIds();
    }
  }, [rfpQuery.isFetchedAfterMount]);

  //   const pageDataQuery = useFetchCurrentPage({
  //     initialData: {},
  //   });

  const gradedRFPs = rfps.filter((rfp) => rfp.progress === "graded");
  const gradedRFPIds = gradedRFPs.map((rfp) => rfp.id);
  const pendingRFPs = rfps.filter((rfp) => rfp.progress === "pending");
  const submittedRFPs = rfps.filter((rfp) => rfp.progress === "submitted");
  const excludedRFPIds = rfps.filter((rfp) => !selectedIds.has(rfp.id)).map((rfp) => rfp.id);

  React.useEffect(() => {
    if (!initialFetched && rfpQuery.isFetchedAfterMount) {
      setInitialFetched(true);
    }
  }, [rfpQuery.isFetchedAfterMount]);

  if (!initialFetched) {
    return <BlockUI show={true} message="Fetching RFPs..." />;
  }

  return (
    <>
      <Helmet title="RFP Weekly Log" />
      <Breadcrumbs>
        <MuiNavLink href="/v2/dashboard/">
          <Typography color="textSecondary">Dashboard</Typography>
        </MuiNavLink>
        {/* <Link href="/v2/marketing/"> */}
        <Typography color="textSecondary">Marketing</Typography>
        {/* </Link> */}
        <Typography color="textPrimary">RFP Weekly Log</Typography>
      </Breadcrumbs>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>
            <PaperItem.SelectedCheckbox
              indeterminate={!allIdsSelected && selectedIds.size !== 0}
              checked={allIdsSelected}
              onChange={(event, checked) => {
                if (checked) {
                  addAllSelectedIds();
                } else {
                  removeAllSelectedIds();
                }
              }}
            />{" "}
            RFP Weekly Log
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                disabled={rfpQuery.isFetching}
                onClick={(event) => {
                  saveExcel(
                    rfps.map((item) => {
                      return {
                        Office: item.office_display,
                        Progress: item.progress_display,
                        Status: item.status_display,
                        "Due Date": item.due_date,
                        Address: item.address1,
                        City: item.city,
                        SF: item.square_footage,
                        Budget: item.anticipated_budget,
                        CM: item?.construction_manager?.name,
                        Team: [item?.project_executive, item?.project_manager, item?.superintendent]
                          .filter((member) => member)
                          .map((teamMember) => {
                            if (teamMember) {
                              return teamMember.last_name;
                            }
                          })
                          .join(", "),
                        "Interview/Prep Status": item.interview_prep_status,
                        Result: item.result,
                      };
                    }),
                    `RFP Weekly Log`
                  );
                }}
              >
                Export Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.PDFButton
                href={`/reports2/marketing/rfp/weekly-log/?${qs.stringify(filterParams)}&exclude=[${excludedRFPIds.join(
                  ","
                )}]`}
                title={`Print Selected (${selectedIds.size})`}
                disabled={selectedIds.size < 1}
                target="_blank"
              />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton isFetching={rfpQuery.isFetching} onClick={() => rfpQuery.refetch()} />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <FilterSelect
                label="Status"
                name="status"
                options={statusChoices}
                value={filterOptions.Status.value}
                onChange={(value, label) => {
                  // setPage(1);
                  setFilterOption("Status", value, label);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FilterSelect
                label="Progress"
                name="progress"
                options={weeklyLogProgressChoices}
                value={filterOptions.Progress.value}
                onChange={(value, label) => {
                  // setPage(1);
                  setFilterOption("Progress", value, label);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FilterSelect
                label="Office"
                name="office"
                options={officeChoices}
                value={filterOptions.Office.value}
                onChange={(value, label) => {
                  // setPage(1);
                  setFilterOption("Office", value, label);
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  // setPage(1);
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  // setPage(1);
                  clearAllFilterOptions();
                }}
              />
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          {(filterOptions.Progress.value === "graded" || filterOptions.Progress.value === "") && (
            <>
              <PaperItem.HeaderLabel label="Graded RFPs" />

              {gradedRFPs.map((rfp) => {
                return (
                  <WeeklyLogRFPPaperItem
                    key={rfp.id}
                    rfp={rfp}
                    isSelected={selectedIds.has(rfp.id)}
                    addSelectedId={addSelectedId}
                    removeSelectedId={removeSelectedId}
                    onClickEdit={() => {
                      setActiveRFP(rfp);
                      setEditDialogIsOpen(true);
                    }}
                  />
                );
              })}
            </>
          )}
          {(filterOptions.Progress.value === "pending" || filterOptions.Progress.value === "") && (
            <>
              <PaperItem.HeaderLabel label="Pending RFPs" />
              {officesInQuery.map((officeDisplay: string) => {
                return (
                  <React.Fragment key={`pending.${officeDisplay}`}>
                    <PaperItem.HeaderLabel label={officeDisplay} labelVariant="h6" />
                    {pendingRFPs
                      .filter((rfp) => rfp.office_display === officeDisplay)
                      .map((rfp) => {
                        return (
                          <WeeklyLogRFPPaperItem
                            key={rfp.id}
                            rfp={rfp}
                            isSelected={selectedIds.has(rfp.id)}
                            addSelectedId={addSelectedId}
                            removeSelectedId={removeSelectedId}
                            onClickEdit={() => {
                              setActiveRFP(rfp);
                              setEditDialogIsOpen(true);
                            }}
                          />
                        );
                      })}
                  </React.Fragment>
                );
              })}
            </>
          )}

          {(filterOptions.Progress.value === "submitted" || filterOptions.Progress.value === "") && (
            <>
              <PaperItem.HeaderLabel label="Submitted RFPs" />
              {officesInQuery.map((officeDisplay: string) => {
                return (
                  <React.Fragment key={`submitted.${officeDisplay}`}>
                    <PaperItem.HeaderLabel label={officeDisplay} labelVariant="h6" />
                    {submittedRFPs
                      .filter((rfp) => rfp.office_display === officeDisplay)
                      .map((rfp) => {
                        return (
                          <WeeklyLogRFPPaperItem
                            key={rfp.id}
                            rfp={rfp}
                            isSelected={selectedIds.has(rfp.id)}
                            addSelectedId={addSelectedId}
                            removeSelectedId={removeSelectedId}
                            onClickEdit={() => {
                              setActiveRFP(rfp);
                              setEditDialogIsOpen(true);
                            }}
                          />
                        );
                      })}
                  </React.Fragment>
                );
              })}
            </>
          )}
        </PaperPanel.Body>
      </PaperPanel>
      <RFPNotesQuickEditDialog
        open={editDialogIsOpen}
        onClose={() => setEditDialogIsOpen(false)}
        initialValues={{
          id: activeRFP?.id,
          result: activeRFP?.result,
          notes: activeRFP?.notes,
          interview_prep_status: activeRFP?.interview_prep_status,
        }}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          setEditDialogIsOpen(false);
          updateRFP.mutateAsync(values).then(() => {
            blockUI.unblockUI();
          });
        }}
      />
    </>
  );
};

export default RFPWeeklyLog;
