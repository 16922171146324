import {faElevator, faFileCertificate, faForklift, faQrcode, faTruckFire} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import qs from "query-string";
import React from "react";
import {Field, Form as FinalForm} from "react-final-form";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router";
import {FinalFormKeyboardShortcuts, identity} from "../../../js/components/FinalFormEnhancers";
import {SimpleAutoselectMui} from "../../components/forms/Fields";
import FormActions from "../../components/forms/FormActions";
import {PageHeader} from "../../components/PageHeader";
import {StatusLabel} from "../../components/Status";
import Typography2 from "../../components/Typography2";
import useBlockUI from "../../hooks/useBlockUI";
import {usePostCurrentPage} from "../../hooks/useSentinelAPI";
import UnauthenticatedLayout from "../../layouts/UnauthenticatedLayout";

const useStyles = makeStyles((theme) => {
  const {transitions, breakpoints, palette, shape, typography, spacing} = theme;
  return {
    radioButton: {
      color: palette.primary.main,
      minWidth: 55,
      width: "100%",
      "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: palette.primary.main,
        fontWeight: 900,
      },
    },
    helperText: {
      "& .MuiFormHelperText-root": {
        color: "#007bff",
      },
    },
  };
});

const ExternalTwilioContactCertifications = (props) => {
  const {twilioContact, project, certifications, certificationsForOthers, operatorOptions = [], ...rest} = props;
  const [createFormDialogIsOpen, setCreateFormDialogIsOpen] = React.useState(false);
  const [contactSelectDialogIsOpen, setContactSelectDialogIsOpen] = React.useState(false);
  const [certificationType, setCertificationType] = React.useState(null);
  const isSpanish = twilioContact?.language === "SP";
  const location = useLocation();
  const qsValues = qs.parse(location.search);
  const date = qsValues?.date;
  const wellnessRedirectURL = `/twilio-contact/${twilioContact.uuid}/projects/${project.uuid}/wellness-check/${date}/`;

  const blockUi = useBlockUI();
  const postCurrentPage = usePostCurrentPage();

  type CertificationType = "forklift" | "scissor_lift" | "boom_lift";

  const createCertification = (certification_type: CertificationType, createForUUID = twilioContact.uuid) => {
    blockUi.blockUI(isSpanish ? "Creando Certificación..." : "Creating Certification...");
    setCreateFormDialogIsOpen(false);

    postCurrentPage
      .mutateAsync({
        action: "create_certification",
        certification_type: certification_type,
        create_for_uuid: createForUUID,
        created_by: twilioContact,
      })
      .then((response) => {
        window.location.href = `/projects/${project.uuid}/twilio-contact/${
          response.twilio_contact.uuid
        }/certifications/${response.uuid}${response.created_by_other ? `?creator=${twilioContact.uuid}` : `?date=${date}`}`;
      });
  };

  const ButtonComponent = (props: {
    certification: {
      certification_type: CertificationType;
      expiration_date?: string;
      uuid?: string;
      certification_created_by?: {id: number; uuid: string};
      created_by_other?: boolean;
      twilio_contact?: {id: number; name: string; uuid: string};
    };
    onClickCertification?: () => void;
    href?: string;
    isNew?: boolean;
    isOwnedByCurrentUser?: boolean;
  }) => {
    const {certification, onClickCertification, isNew, isOwnedByCurrentUser} = props;

    const createdForDisplay =
      certification.created_by_other && !isOwnedByCurrentUser && certification?.twilio_contact?.name
        ? `(${certification?.twilio_contact?.name})`
        : "";

    let buttonLinksTo =
      props.href ??
      `/projects/${project.uuid}/twilio-contact/${certification?.twilio_contact?.uuid}/certifications/${certification?.uuid}/?`;
    if (certification.created_by_other && !isOwnedByCurrentUser) {
      buttonLinksTo += `&creator=${certification?.certification_created_by?.uuid}`;
    }
    if (date) {
      buttonLinksTo += `&date=${date}`;
    }

    const additionalProps = {};
    if (!isNew) {
      additionalProps["href"] = buttonLinksTo;
    }
    switch (certification?.certification_type) {
      case "forklift":
        return (
          <Button
            fullWidth
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faForklift} />}
            onClick={onClickCertification}
            {...additionalProps}
          >
            {isSpanish ? `Montacargas` : `Forklift`} {createdForDisplay}
          </Button>
        );
      case "scissor_lift":
        return (
          <Button
            fullWidth
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faElevator} />}
            onClick={onClickCertification}
            {...additionalProps}
          >
            {isSpanish ? `Elevación de tijera` : `Scissor Lift`} {createdForDisplay}
          </Button>
        );
      case "boom_lift":
        return (
          <Button
            fullWidth
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faTruckFire} />}
            onClick={onClickCertification}
            {...additionalProps}
          >
            {isSpanish ? `Elevación de pluma` : `Boom Lift`} {createdForDisplay}
          </Button>
        );
    }
  };

  return (
    <UnauthenticatedLayout>
      <Helmet title={isSpanish ? `Tus certificaciones` : `Your Certifications`} />

      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faFileCertificate} /> {isSpanish ? `Tus certificaciones` : `Your Certifications`}
          </PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right></PageHeader.Right>
      </PageHeader>
      <Grid container spacing={2}>
        {certifications.length === 0 && (
          <Grid item xs={12}>
            <Box textAlign="center" my={5}>
              <Typography variant="h4">
                {isSpanish ? "No se encontraron certificaciones" : "No Certifications Found"}
              </Typography>
            </Box>
          </Grid>
        )}
        {certifications.map((certification) => {
          return (
            <Grid item xs={12} key={certification.id}>
              <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
                <ButtonComponent certification={certification} isOwnedByCurrentUser />
                <StatusLabel status={certification.status_display} hint="twilioContactCertification" width="100%" />
                {certification?.expiration_date && (
                  <Box width={1} display="flex" justifyContent="center">
                    <Typography2
                      type={
                        certification.status === "expired" || certification.days_until_expiration < 30
                          ? "error"
                          : "metadata"
                      }
                    >
                      {isSpanish ? `Vence` : `Expiration`}: {certification.expiration_date}
                    </Typography2>
                  </Box>
                )}
              </Box>
            </Grid>
          );
        })}
        <Typography variant="h4">Created For Others</Typography>
        {certificationsForOthers.map((certification) => {
          return (
            <Grid item xs={12} key={certification.id}>
              <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
                <ButtonComponent certification={certification} />
                <StatusLabel status={certification.status_display} hint="twilioContactCertification" width="100%" />
                {certification?.expiration_date && (
                  <Box width={1} display="flex" justifyContent="center">
                    <Typography2
                      type={
                        certification.status === "expired" || certification.days_until_expiration < 30
                          ? "error"
                          : "metadata"
                      }
                    >
                      {isSpanish ? `Vence` : `Expiration`}: {certification.expiration_date}
                    </Typography2>
                  </Box>
                )}
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <Box mb={1} mt={3}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          size="small"
          startIcon={<FontAwesomeIcon icon={faFileCertificate} />}
          onClick={() => setCreateFormDialogIsOpen(true)}
        >
          {isSpanish ? "Nueva Certificación" : "New Certification"}
        </Button>
      </Box>
      {qsValues?.date && (
        <>
          <Box mb={1} />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="small"
            href={wellnessRedirectURL}
            startIcon={<FontAwesomeIcon icon={faQrcode} />}
          >
            {isSpanish ? "Volver al código QR" : "Back to QR Code"}
          </Button>
        </>
      )}

      <Dialog open={createFormDialogIsOpen} onClose={() => setCreateFormDialogIsOpen(false)}>
        <DialogTitle>{isSpanish ? "Crear Certificacion" : "Create Certification"}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {isSpanish
              ? "Seleccione el tipo de certificacion que desea crear"
              : "Select the type of certification you'd like to create"}
          </Typography>
          {["forklift", "scissor_lift", "boom_lift"].map((certification_type: CertificationType) => {
            return (
              <Box my={2} key={certification_type}>
                <ButtonComponent
                  certification={{certification_type: certification_type}}
                  // onClickCertification={() => createCertification(certification_type)}
                  onClickCertification={() => {
                    setCertificationType(certification_type);
                    setContactSelectDialogIsOpen(true);
                    setCreateFormDialogIsOpen(false);
                  }}
                  isNew
                />
              </Box>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCreateFormDialogIsOpen(false)}>{isSpanish ? "Cancelar" : "Cancel"}</Button>
        </DialogActions>
      </Dialog>
      <CreateCertificationForTwilioContactSelectDialog
        isOpen={contactSelectDialogIsOpen}
        handleClose={() => setContactSelectDialogIsOpen(false)}
        operatorOptions={operatorOptions}
        onSubmit={(values) => {
          setContactSelectDialogIsOpen(false);
          if (values.certIsFor === "other") {
            createCertification(certificationType, values.operator);
          } else {
            createCertification(certificationType, twilioContact.uuid);
          }
        }}
        isSpanish={isSpanish}
      />
    </UnauthenticatedLayout>
  );
};

export default ExternalTwilioContactCertifications;

const CreateCertificationForTwilioContactSelectDialog = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  handleClose: () => void;
  isSpanish?: boolean;

  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, isSpanish, operatorOptions, ...rest} = props;
  const classes = useStyles();
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values, hasValidationErrors}) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">
                  {isSpanish ? "¿Para quién es la certificación?" : "Who is the certification for?"}
                </DialogTitle>
                <DialogContent>
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormLabel>
                          {isSpanish
                            ? "Seleccione el operador para el que desea crear la certificación"
                            : "Select the operator you'd like to create the certification for"}
                        </FormLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name={"certIsFor"}
                          parse={identity}
                          validate={(value) => {
                            return value ? undefined : "Required";
                          }}
                        >
                          {(fieldProps) => {
                            const {input, meta, ...rest} = fieldProps;
                            return (
                              <FormControl error={meta.invalid} fullWidth required>
                                <ToggleButtonGroup
                                  size="small"
                                  exclusive
                                  {...input}
                                  onChange={(event, value) => {
                                    if (value === "other") {
                                      input.onChange(value);
                                    } else {
                                      form.change("certIsFor", value);
                                      form.change("operator", null);
                                      form.submit();
                                    }
                                  }}
                                >
                                  <ToggleButton value={"user"} className={classes.radioButton}>
                                    {isSpanish ? "Yo" : "Me"}
                                  </ToggleButton>
                                  <ToggleButton value={"other"} className={classes.radioButton}>
                                    {isSpanish ? "Alguien más" : "Someone Else"}
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              </FormControl>
                            );
                          }}
                        </Field>
                      </Grid>
                      {values.certIsFor === "other" && (
                        <Grid item xs={12}>
                          <SimpleAutoselectMui
                            name={`operator`}
                            label={isSpanish ? `Operador` : `Operator`}
                            options={operatorOptions}
                            required
                            renderOption={(option) => {
                              return (
                                <Box display="flex" alignItems="center">
                                  <Box mr={1}>{option.label}</Box>
                                </Box>
                              );
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <Button variant="contained" color="primary" type="submit" disabled={hasValidationErrors}>
                    Create
                  </Button>
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};
