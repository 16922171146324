import {Box, Link, Typography} from "@material-ui/core";
import {Helmet} from "react-helmet";
import {useParams} from "react-router";
import {Breadcrumbs} from "../../../components/Breadcrumbs";
import BlockUI from "../../../components/GlobalLoaders";
import {MuiNavLink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import useBlockUI from "../../../hooks/useBlockUI";
import {useSentinelDetailAPI, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {CompetitorBidPaperItem} from "./RFPComponents";
import {RFPCompetitorBidDialogForm, RFPOnboardingForm} from "./RFPForms";
import React from "react";

const RequestForPricingDetail = (props) => {
  const {isFetching, ...rest} = props;
  const [createCompetitorBidDialogOpen, setCreateCompetitorBidDialogOpen] = React.useState(false);
  const [editCompetitorBidDialogOpen, setEditCompetitorBidDialogOpen] = React.useState(false);
  const [activeCompetitorBid, setActiveCompetitorBid] = React.useState(null);
  const {rfpId} = useParams();
  const blockUI = useBlockUI();
  const {
    query: rfpQuery,
    create: createRFP,
    update: updateRFP,
    delete: deleteRFP,
  } = useSentinelDetailAPI(`marketing/rfps/${rfpId}/`, {
    initialData: {},
  });

  const {query: tagQuery} = useSentinelListAPI(`marketing/rfps/tags/`, {
    initialData: [],
  });
  const tagOptions = tagQuery.data;

  const {
    query: competitorBidQuery,
    create: createCompetitorBid,
    update: updateCompetitorBid,
    delete: deleteCompetitorBid,
  } = useSentinelListAPI(`marketing/rfps/competitor-bids/?rfp_id=${rfpId}`, {
    initialData: {
      results: [],
    },
  });

  const competitorBids = competitorBidQuery.data.results;

  // const pageDataQuery = useFetchCurrentPage({
  //   refetchOnWindowFocus: false,
  //   initialData: {},
  // });
  // const pageData = pageDataQuery.data;

  const rfp = rfpQuery.data;

  if (!rfpQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message={`Fetching RFP Details...`} />;
  }

  return (
    <>
      <Helmet title={`RFP - ${rfp.display}`} />
      <Breadcrumbs>
        <MuiNavLink href="/v2/dashboard/">
          <Typography color="textSecondary">Dashboard</Typography>
        </MuiNavLink>
        {/* <Link href="/v2/marketing/"> */}
        <Typography color="textSecondary">Marketing</Typography>
        {/* </Link> */}
        <Link href="/v2/marketing/rfps/">
          <Typography color="textSecondary">RFPs</Typography>
        </Link>
        <Typography color="textPrimary">{rfp.display}</Typography>
      </Breadcrumbs>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>RFP - {rfp.display}</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.BoxFilesButton href={`/v2/box/files/marketing/rfp/${rfpId}/`} />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.RefreshButton
                isFetching={rfpQuery.isFetching || tagQuery.isFetching || competitorBidQuery.isFetching}
                onClick={() => {
                  rfpQuery.refetch();
                  tagQuery.refetch();
                  competitorBidQuery.refetch();
                }}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body p={1}>
          <RFPOnboardingForm
            onSubmit={(values) => {
              blockUI.blockUI("Saving...");
              tagQuery.refetch();
              updateRFP.mutateAsync(values).then(() => blockUI.unblockUI());
            }}
            initialValues={rfp}
            tagOptions={tagOptions.map((tag) => tag.label)}
            isFetching={isFetching}
          />
        </PaperPanel.Body>
      </PaperPanel>
      <Box my={1} />
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>Competitors</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton
                onClick={() => {
                  setCreateCompetitorBidDialogOpen(true);
                }}
              >
                Add Competitor
              </PaperPanel.Header.CreateButton>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          {competitorBids.map((competitorBid) => {
            return (
              <CompetitorBidPaperItem
                key={competitorBid.id}
                competitorBid={competitorBid}
                onEdit={() => {
                  setActiveCompetitorBid(competitorBid);
                  setEditCompetitorBidDialogOpen(true);
                }}
              />
            );
          })}
        </PaperPanel.Body>
      </PaperPanel>

      <RFPCompetitorBidDialogForm
        onSubmit={(values) => {
          blockUI.blockUI("Creating...");
          setCreateCompetitorBidDialogOpen(false);
          createCompetitorBid.mutateAsync(values).then(() => blockUI.unblockUI());
        }}
        initialValues={{rfp: rfp}}
        isNew
        open={createCompetitorBidDialogOpen}
        onClose={() => setCreateCompetitorBidDialogOpen(false)}
      />

      <RFPCompetitorBidDialogForm
        onSubmit={(values) => {
          blockUI.blockUI("Updating...");
          setEditCompetitorBidDialogOpen(false);
          updateCompetitorBid.mutateAsync(values).then(() => blockUI.unblockUI());
        }}
        initialValues={activeCompetitorBid}
        open={editCompetitorBidDialogOpen}
        onClose={() => setEditCompetitorBidDialogOpen(false)}
      />
    </>
  );
};

export default RequestForPricingDetail;
