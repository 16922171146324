import {Box, Grid, Typography} from "@material-ui/core";
import qs from "qs";
import React from "react";
import {Helmet} from "react-helmet";
import PaperPanel from "../../../components/PaperPanel";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";

import {faFileExcel} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import {Breadcrumbs} from "../../../components/Breadcrumbs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterDate, FilterSearch} from "../../../components/Filters";
import {MuiNavLink} from "../../../components/Links";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {TabCountChip} from "../../../components/Tabs";
import {makeNovoClasses} from "../../../theme";
import {saveExcel} from "../../../utils/excel";

const RFPCompetitors = (props) => {
  const {...rest} = props;
  const [ordering, setOrdering] = React.useState("");
  const novoClasses = makeNovoClasses();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions([
    "Search",
    "Status",
    "Start Date",
    "End Date",
  ]);

  const filterParams = {
    status: filterOptions.Status.value,
    q: filterOptions.Search.value,
    date_min: filterOptions["Start Date"].value,
    date_max: filterOptions["End Date"].value,
  };
  const {query: competitorQuery} = useSentinelListAPI(
    `marketing/rfps/competitors/?page_size=10000&${qs.stringify(filterParams)}`,
    {
      initialData: {
        results: [],
      },
    }
  );

  const competitors = competitorQuery.data.results;

  return (
    <>
      <Helmet title={`RFP Competitors`} />
      <Breadcrumbs>
        <MuiNavLink href="/v2/dashboard/">
          <Typography color="textSecondary">Dashboard</Typography>
        </MuiNavLink>
        {/* <Link href="/v2/marketing/"> */}
        <Typography color="textSecondary">Marketing</Typography>

        {/* </Link> */}
        <Typography color="textSecondary">RFPs</Typography>
        <Typography color="textPrimary">Competitors</Typography>
      </Breadcrumbs>
      <PaperPanel>
        <PaperPanel.Header isLoading={competitorQuery.isFetching}>
          <PaperPanel.Header.Title>
            <Box display="flex" alignItems="center">
              Competitors <TabCountChip isLoading={competitorQuery.isFetching} count={competitorQuery.data.count} />
            </Box>
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.PDFButton
                href={`/reports2/marketing/rfps/competitors/?${qs.stringify(filterParams)}&order_by=${ordering}`}
                target="_blank"
              />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                onClick={() => {
                  saveExcel(
                    competitors.map((competitor) => {
                      return {
                        Name: competitor.name,
                        "Fee Average": competitor.average_fee ? `${competitor.average_fee.toFixed(2)}%` : "N/A",
                        "Insurance Average": competitor.average_insurance_rate
                          ? `${competitor.average_insurance_rate.toFixed(2)}%`
                          : "N/A",
                        "Weekly GCs Average": competitor.average_weekly_gcs,
                        Bids: competitor.total_bids,
                        Wins: competitor.total_wins,
                        "Win Rate": competitor.win_rate ? `${competitor.win_rate}%` : "N/A",
                        "Last Bid": competitor.last_bid_date,
                      };
                    }),
                    `Competitors`,
                    [{wch: 50}, {wch: 15}, {wch: 15}, {wch: 15}, {wch: 10}, {wch: 10}, {wch: 10}, {wch: 15}]
                  );
                }}
                disabled={competitorQuery.isFetching || competitors.length < 1}
              >
                Get Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                isFetching={competitorQuery.isFetching}
                onClick={competitorQuery.refetch}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <FilterSearch
                name="Search"
                label="Search"
                value={filterOptions.Search.value}
                onChange={(value) => {
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item sm={3} xs={6}>
              <FilterDate
                name="Start Date"
                value={filterOptions["Start Date"].value}
                label="Start Date"
                onChange={(date) => {
                  setFilterOption("Start Date", date, date);
                  //   setDateRange("");
                }}
                helperText="Last Bid Date From"
              />
            </Grid>
            <Grid item sm={3} xs={6}>
              <FilterDate
                name="End Date"
                value={filterOptions["End Date"].value}
                label="End Date"
                onChange={(date) => {
                  setFilterOption("End Date", date, date);
                  //   setDateRange("");
                }}
                helperText="Last Bid Date To"
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                }}
              />
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            columns={[
              {
                Header: "Company",
                accessor: "name",
                Cell: ({value, row}) => (
                  <MuiNavLink to={row.original.url} style={{cursor: "pointer"}} underline="always">
                    {value}
                  </MuiNavLink>
                ),
              },
              {
                Header: "Fee Average",
                accessor: "average_fee",
                Cell: ({value}) => (value ? `${value.toFixed(2)}%` : "N/A"),
              },
              {
                Header: "Insurance Average",
                accessor: "average_insurance_rate",
                Cell: ({value}) => (value ? `${value.toFixed(2)}%` : "N/A"),
              },
              {
                Header: "Weekly GCs Average",
                accessor: "average_weekly_gcs",
                Cell: ({value}) => (value ? `$${value.toFixed(2)}` : "N/A"),
              },
              {Header: "Bids", accessor: "total_bids"},
              {Header: "Wins", accessor: "total_wins"},
              {Header: "Win Rate", accessor: "win_rate", Cell: ({value}) => (value ? `${value}%` : "N/A")},
              {Header: "Last Bid", accessor: "last_bid_date"},
            ]}
            data={competitors}
            initialState={{
              sortBy: [
                {
                  id: "name",
                  desc: false,
                },
              ],
            }}
            onSortBy={(sortBy) => {
              const sorting = sortBy[0];
              if (!sorting) return setOrdering("");
              const ordering = sorting.desc ? `-${sorting.id}` : sorting.id;
              setOrdering(ordering);
            }}
            {...rest}
          />
        </PaperPanel.Body>
      </PaperPanel>
    </>
  );
};

export default RFPCompetitors;
