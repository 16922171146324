import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Link,
  MenuItem as MenuItemMui,
} from "@material-ui/core";

import pluralize from "pluralize";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {useLocation} from "react-router";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {axiosAPI} from "../../../api";
import ActiveFieldsFormSpy from "../../../components/forms/ActiveFieldsFormSpy";
import corStatuses from "../../../components/forms/choices/corStatuses.json";
import {
  ApiSelectMui,
  DatePickerMui,
  NovoRestrictedSignersAutoselectMui,
  ProjectContactAutoselectMui,
  ProjectTagAutocompleteMui,
  SelectStatus,
  TextFieldMui,
  TinyMCEMui,
} from "../../../components/forms/Fields";
import {beforeDialogClose} from "../../../components/forms/Form";
import FormActions from "../../../components/forms/FormActions";
import {StatusIcon} from "../../../components/Icons";
import InnerFooter from "../../../components/InnerFooter";
import {PrevNext} from "../../../components/PrevNext";
import {SuperuserInfo} from "../../../components/SuperuserInfo";
import useFormFocusFieldRef from "../../../hooks/useFormFocusFieldRef";

export const CORSelectStatus = (props: {cor: any; [rest: string]: any}) => {
  const {cor, ...rest} = props;
  if (cor.parent_id || cor.pcco) {
    return (
      <>
        <StatusIcon status={cor.status} type="changeOrder" /> {cor.status_display}
        {cor.pcco && (
          <FormHelperText>
            Status cannot be change on COR with an <Link href={cor.pcco?.url}>OCO: {cor.pcco?.display}</Link>
          </FormHelperText>
        )}
      </>
    );
  }
  return <SelectStatus options={corStatuses} name="status" label="Status" {...rest} />;
};

export const CORDialogForm = React.forwardRef(
  (
    props: {
      projectId: number;
      onSubmit: (values: any) => void;
      handleClose: () => void;
      isOpen: boolean;
      isReadOnly?: boolean;
      activeCOR: any;
      cors: any;
      [rest: string]: any;
    },
    ref
  ) => {
    const {
      projectId,
      onSubmit,
      isOpen,
      handleClose,
      isReadOnly,
      setActiveCOR,
      activeCOR,
      cors,
      isNew,
      beforePrevNext,
      afterPrevNext,
      initialFocusField,
      setInitialFocusField,
      restrictNovoSigners = false,
      ...rest
    } = props;
    const formPropsRef = React.useRef(null);

    const formRef = useFormFocusFieldRef(initialFocusField, [props.initialValues.id, props.initialFocusField]);

    const onFocusChange = (props) => {
      const {active} = props;
      if (setInitialFocusField && active) setInitialFocusField(active);
    };

    return (
      <>
        <Dialog
          open={isOpen}
          // onClose={(event, reason) => beforeDialogClose(handleClose, !formPropsRef.current.pristine)}
          onClose={handleClose}
          maxWidth="md"
          fullWidth
        >
          <FinalForm onSubmit={onSubmit} {...rest}>
            {(props) => {
              const {handleSubmit, form, submitting, pristine = true, values, hasValidationErrors} = props;
              formPropsRef.current = props;

              return (
                <form onSubmit={handleSubmit} noValidate={true} autoComplete="off" ref={formRef}>
                  <DialogTitle id="quick-edit-form">
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        {/* <StatusLabel status={values.status_display} /> */}
                        {isNew ? "Create COR" : "Update COR"}
                      </Grid>
                      <Grid item>
                        <PrevNext
                          displayProp="number_display"
                          setActiveItem={setActiveCOR}
                          items={cors}
                          activeItem={activeCOR}
                          beforePrevNext={!pristine && beforePrevNext ? () => beforePrevNext(values) : undefined}
                          afterPrevNext={!pristine && afterPrevNext ? () => afterPrevNext() : undefined}
                          disabled={hasValidationErrors && !pristine}
                        />
                      </Grid>
                    </Grid>
                  </DialogTitle>
                  <DialogContent>
                    <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                      <Grid container spacing={2}>
                        <CORCommonFields
                          values={values}
                          isReadOnly={isReadOnly}
                          projectId={projectId}
                          isNew={isNew}
                          restrictNovoSigners={restrictNovoSigners}
                        />
                      </Grid>
                      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    </FinalFormKeyboardShortcuts>

                    <SuperuserInfo
                      objects={[
                        {
                          contentType: "cors",
                          model: "cor",
                          id: values.id,
                          title: values.display,
                        },
                      ]}
                    />
                  </DialogContent>
                  <DialogActions>
                    <FormActions.SaveButton
                      label="Save"
                      disabled={pristine}
                      onClick={() => form.change("submitMode", "saveAndClose")}
                    />
                    {isNew && (
                      <FormActions.SaveAndEditButton
                        disabled={submitting || pristine}
                        onClick={() => form.change("submitMode", "editAfterSubmit")}
                      />
                    )}
                    {isNew && (
                      <FormActions.SaveAndAddButton
                        disabled={pristine}
                        onClick={() => form.change("submitMode", "addAnother")}
                      />
                    )}
                    <FormActions.CancelButton
                      onClick={() => beforeDialogClose(handleClose, !formPropsRef.current.pristine)}
                    />
                  </DialogActions>
                  <ActiveFieldsFormSpy onChange={onFocusChange} />
                </form>
              );
            }}
          </FinalForm>
        </Dialog>
      </>
    );
  }
);

export const CORDetailForm = (props) => {
  const {onSubmit, isReadOnly, projectId, cor, restrictNovoSigners = false, ...rest} = props;
  const {pathname} = useLocation();
  return (
    <>
      <FinalForm onSubmit={onSubmit} {...rest}>
        {(props) => {
          const {handleSubmit, form, submitting, pristine, values, ...rest} = props;
          return (
            <>
              <BeforeUnload block={!pristine} />
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <CORCommonFields
                      values={values}
                      projectId={projectId}
                      isReadOnly={isReadOnly}
                      isNew={false}
                      restrictNovoSigners={restrictNovoSigners}
                    />

                    <Grid item xs={12}>
                      <ProjectTagAutocompleteMui projectId={projectId} name="tags" label="Tags" />
                    </Grid>
                    <Grid item xs={12}>
                      <TinyMCEMui name="notes" label="Notes" disabled={isReadOnly} />
                    </Grid>
                  </Grid>
                  <InnerFooter>
                    <Box display="flex">
                      <FormActions.SaveButton disabled={submitting || pristine} />
                      <Box ml={1} />
                      <FormActions.DeleteButton
                        href={`/v2/projects/${projectId}/delete/cors/cor/${values.id}/?next=${pathname}&cancel=${pathname}#tab=cor`}
                        disabled={isReadOnly}
                      />
                    </Box>
                  </InnerFooter>
                </FinalFormKeyboardShortcuts>
              </form>
            </>
          );
        }}
      </FinalForm>
    </>
  );
};

const CORCommonFields = (props) => {
  const {values, isReadOnly, projectId, isNew, restrictNovoSigners} = props;
  const validateNumber = async (value, values, field) => {
    if (!values.number) return "Required";
    if (values.number < 1) return "Must use a positive number";
    if (!field.active) return; // Hack: Don't fire validation when typing in TinyMCE
    const response = await axiosAPI.get(
      `/projects/${projectId}/cors/validate-number/?number=${values?.number || ""}${!isNew ? `&id=${values.id}` : ""}`
    );
    if (response.data.error) {
      return response.data.error;
    }
  };
  return (
    <>
      <Grid item xs={6}>
        <CORSelectStatus label="Status" name="status" cor={values} required disabled={values?.pcco?.is_closed} />
      </Grid>
      <Grid item xs={3}>
        <TextFieldMui
          name="number"
          label="Number"
          type="number"
          fieldProps={{
            validate: validateNumber,
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextFieldMui name="revision" label="Revision" type="number" disabled={true} />
      </Grid>
      <Grid item xs={12}>
        <TextFieldMui name="description" label="Description" required autoFocus />
      </Grid>
      <Grid item xs={6}>
        {restrictNovoSigners ? (
          <NovoRestrictedSignersAutoselectMui
            projectId={projectId}
            name="from_contact"
            label="From"
            disabled={isReadOnly}
          />
        ) : (
          <ProjectContactAutoselectMui projectId={projectId} name="from_contact" label="From" disabled={isReadOnly} />
        )}
      </Grid>
      <Grid item xs={6}>
        <ProjectContactAutoselectMui
          projectId={projectId}
          name="to_contact"
          label="To"
          required
          disabled={isReadOnly}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePickerMui name="initiated_date" label="Initiated Date" disabled={isReadOnly} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePickerMui name="required_date" label="Required Date" disabled={isReadOnly} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePickerMui name="completed_date" label="Completed Date" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePickerMui name="executed_date" label="Executed Date" disabled={isReadOnly} />
      </Grid>
    </>
  );
};

export const CORBulkUpdateForm = (props: {
  projectId: number;
  onSubmit: (values: any) => void;
  handleClose: () => void;
  isOpen: boolean;
  isReadOnly?: boolean;
  corCount?: number;
  restrictNovoSigners?: number;

  [rest: string]: any;
}) => {
  const {
    projectId,
    onSubmit,
    isOpen,
    handleClose,
    isReadOnly = false,
    corCount,
    statusOptions,
    restrictNovoSigners = false,
    ...rest
  } = props;

  return (
    <>
      <Box display="flex" justifyContent={"center"} mx={"auto"}>
        <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
          <FinalForm onSubmit={onSubmit} initialValues={{tags: []}} {...rest}>
            {({handleSubmit, form, submitting, pristine = true, values}) => (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="bulk-update-form">
                  Bulk Update {corCount} {pluralize("Change Order Request", corCount)}
                </DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <SelectStatus options={corStatuses} label="Status" name="status" type="changeOrder" />
                      </Grid>
                      <Grid item xs={12}>
                        <ProjectContactAutoselectMui projectId={projectId} name="to_contact" label="To" />
                      </Grid>
                      <Grid item xs={12}>
                        {restrictNovoSigners ? (
                          <NovoRestrictedSignersAutoselectMui projectId={projectId} name="from_contact" label="From" />
                        ) : (
                          <ProjectContactAutoselectMui projectId={projectId} name="from_contact" label="From" />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DatePickerMui name="initiated_date" label="Initiated Date" />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DatePickerMui name="required_date" label="Required Date" />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DatePickerMui name="completed_date" label="Completed Date" />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DatePickerMui name="executed_date" label="Executed Date" />
                      </Grid>
                      <Grid item xs={12}>
                        <ProjectTagAutocompleteMui projectId={projectId} name="tags" label="Tags" />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  {/* <FormActions.DeleteButton onClick={onDelete} disabled={isReadOnly} /> */}
                  <FormActions.SaveButton disabled={submitting || pristine || isReadOnly} label={"Bulk Update"} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            )}
          </FinalForm>
        </Dialog>
      </Box>
    </>
  );
};

export const ProjectPCOSelect = (props: {projectId: number; helperText?: string; name: string; label: string}) => {
  const {projectId, ...rest} = props;
  // const baseURL = `projects/${projectId}/pcos/?is_internal=False&parent__isnull=True`;
  const baseURL = `projects/${projectId}/pcos/`;
  return (
    <ApiSelectMui
      name="pco.id"
      label="PCO"
      baseURL={baseURL}
      qsParams={{
        is_internal: "False",
        parent__isnull: "True",
      }}
      {...rest}
    >
      {(option) => (
        <MenuItemMui dense key={option.id} value={option} disabled={option.cor && option.cor.id}>
          <Box alignItems="center">
            <Box display="inline" ml={1} />
            <StatusIcon status={option.status} /> - {option.display}
          </Box>
        </MenuItemMui>
      )}
    </ApiSelectMui>
  );
};
