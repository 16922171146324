import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {Switches as FinalSwitches} from "mui-rff";
import {Form as FinalForm} from "react-final-form";
import {
  AutoselectMui,
  CheckboxMui,
  CompanyAutoselectMui,
  CompanyContactAutoselectMui,
  CSVTagsMui,
  CurrencyFieldMui,
  DatePickerMui,
  GoogleMapsAddressAutocompleteMui,
  GroupedSimpleSelect,
  MaskedInputMui,
  MultipleAutocompleteMui,
  NumberFieldMui,
  PercentFieldMui,
  ProjectAutoselectMui,
  SelectStatus,
  SimpleAutoselectMui,
  SimpleSelect,
  TextFieldMui,
} from "../../../components/forms/Fields";
import offices from "../../../components/forms/choices/offices.json";
import bidTypeOptions from "../../../components/forms/choices/rfpBidTypes.json";
import projectTypeChoices from "../../../components/forms/choices/rfpProjectTypes.json";
import requestTypeChoices from "../../../components/forms/choices/rfpRequestTypes.json";
import sectorTypeChoices from "../../../components/forms/choices/rfpSectors.json";
import statusChoices from "../../../components/forms/choices/rfpStatusTypes.json";

import {faCheck, faCircleInfo, faPlusCircle} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DeleteIcon from "@material-ui/icons/Delete";
import Cookies from "js-cookie";
import {find} from "lodash";
import React from "react";
import {FieldArray} from "react-final-form-arrays";
import {FinalFormKeyboardShortcuts, identity} from "../../../../js/components/FinalFormEnhancers";
import LabeledGroup from "../../../../js/components/LabeledGroup";
import {axiosAPI} from "../../../api";
import DropzoneMui from "../../../components/DropzoneMui";
import InnerFooter from "../../../components/InnerFooter";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import {StatusLabel} from "../../../components/Status";
import ActiveFieldsFormSpy from "../../../components/forms/ActiveFieldsFormSpy";
import {AutoSubmitSection} from "../../../components/forms/AutoSubmitSection";
import FormActions from "../../../components/forms/FormActions";
import contactRoles from "../../../components/forms/choices/contactRoles.json";
import phoneNumberTypes from "../../../components/forms/choices/phoneNumberTypes.json";
import usStates from "../../../components/forms/choices/usStates.json";
import useBlockUI from "../../../hooks/useBlockUI";
import {colorInfo, colorSuccess} from "../../../theme/colors";
import {validateEmailAddress} from "../../../utils/validators";

import {ConfirmationDialog} from "../../../components/Dialogs";
import companyRoles from "../../../components/forms/choices/companyRoles.json";
import csiCodesNovo from "../../../components/forms/choices/csiCodesNovo.json";
import usePermissions from "../../../hooks/usePermissions";

export const RFPOnboardingCommonFields = (props) => {
  const {form, tagOptions = [], isFetching, isNew, isTracked} = props;

  const [showCreateCompanyDialog, setShowCreateCompanyDialog] = React.useState(false);
  const [createCompanyVerificationDialogIsOpen, setCreateCompanyVerificationDialogIsOpen] = React.useState(false);
  const [companyToCreate, setCompanyToCreate] = React.useState(null);
  const [activeFieldName, setActiveFieldName] = React.useState("");
  const [initialRole, setInitialRole] = React.useState(null);

  const validateNumber = async (value, values, field) => {
    if (!value || value === "") return "Required";
    if (value < 1) return "Must use a positive number";
  };

  const blockUI = useBlockUI();

  return (
    <>
      <Grid container spacing={2}>
        {!isNew && (
          <Grid item xs={12} sm={3}>
            <SelectStatus name="status" label="Status" options={statusChoices} required />
          </Grid>
        )}
        <Grid item xs={12} sm={isNew ? 4 : 3}>
          <TextFieldMui name="project_name" label="Project Name" required autoFocus />
        </Grid>

        <Grid item xs={12} sm={isNew ? 4 : 3}>
          <FieldWithAddButton
            onClick={() => {
              setActiveFieldName("client");
              setInitialRole("client");
              setShowCreateCompanyDialog(true);
            }}
          >
            <CompanyAutoselectMui name="client" label="Client" required />
          </FieldWithAddButton>
        </Grid>
        <Grid item xs={12} sm={isNew ? 4 : 3}>
          <SimpleSelect name="office" label="Office" required options={offices} />
        </Grid>

        <Grid item xs={12} container>
          <Grid item xs={12} sm={4}>
            <CheckboxMui name="is_tracked" label="Tracked Lead" />
          </Grid>
          {isTracked && (
            <Grid item xs={12} sm={4}>
              <DatePickerMui name="lead_origin_date" label="Lead Origin Date" required />
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} sm={4}>
          <SimpleAutoselectMui name="sector" label="Sector" options={sectorTypeChoices} required />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SimpleSelect name="project_type" label="Project Type" options={projectTypeChoices} required />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SimpleAutoselectMui name="request_type" label="Request Type" options={requestTypeChoices} required />
        </Grid>

        <Grid item xs={12} sm={4}>
          <SimpleSelect name="bid_type" label="Bid Type" options={bidTypeOptions} required />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePickerMui name="due_date" label="Due Date" required disablePast />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextFieldMui name="due_time" label="Due Time" required />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePickerMui name="construction_start_date" label="Construction Start Date" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePickerMui name="construction_finish_date" label="Construction Finish Date" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberFieldMui
            name="square_footage"
            label="Estimated Square Footage"
            required
            fieldProps={{
              validate: validateNumber,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberFieldMui
            name="anticipated_budget"
            label="Anticipated Budget"
            required
            fieldProps={{
              validate: validateNumber,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePickerMui name="job_walk_date" label="Job Walk Date" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePickerMui name="rfi_deadline_date" label="RFI Deadline" />
        </Grid>

        <Grid item xs={12} container spacing={1}>
          <Grid item xs={12}>
            <GoogleMapsAddressAutocompleteMui
              name="address1"
              label="Address"
              onPlaceSelected={(place) => {
                form.change("address1", place.address1);
                form.change("address2", place.address2);
                form.change("city", place.locality_long_name);
                form.change("state", place.administrative_area_level_1_short_name);
                form.change("postal_code", place.postal_code);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldMui name="address2" label="Address 2" />
          </Grid>
          <Grid item xs={6}>
            <TextFieldMui name="city" label="City" required />
          </Grid>
          <Grid item xs={3}>
            <SimpleSelect name="state" label="State" options={usStates} />
          </Grid>
          <Grid item xs={3}>
            <TextFieldMui name="postal_code" label="Postal Code" />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <CompanyContactAutoselectMui name="project_executive" label="Project Executive" companyId={1547} required />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CompanyContactAutoselectMui
            name="project_manager"
            label={isNew ? "PM Lead" : "Project Manager"}
            companyId={1547}
          />
        </Grid>
        {!isNew && (
          <>
            <Grid item xs={12} sm={6}>
              <CompanyContactAutoselectMui
                name="senior_project_manager"
                label="Senior Project Manager"
                companyId={1547}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CompanyContactAutoselectMui
                name="assistant_project_manager"
                label="Assistant Project Manager"
                companyId={1547}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CompanyContactAutoselectMui name="project_engineer" label="Project Engineer" companyId={1547} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CompanyContactAutoselectMui name="superintendent" label="Superintendent" companyId={1547} />
            </Grid>
          </>
        )}

        <Grid item xs={12} sm={6}>
          <FieldWithAddButton
            onClick={() => {
              setActiveFieldName("architect");
              setInitialRole("architect");
              setShowCreateCompanyDialog(true);
            }}
          >
            <CompanyAutoselectMui name="architect" label="Architect" />
          </FieldWithAddButton>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldWithAddButton
            onClick={() => {
              setActiveFieldName("construction_manager");
              setInitialRole("construction_manager");
              setShowCreateCompanyDialog(true);
            }}
          >
            <CompanyAutoselectMui name="construction_manager" label="Construction Manager" />
          </FieldWithAddButton>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldWithAddButton
            onClick={() => {
              setActiveFieldName("broker");
              setShowCreateCompanyDialog(true);
            }}
          >
            <CompanyAutoselectMui name="broker" label="Broker" />
          </FieldWithAddButton>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldWithAddButton
            onClick={() => {
              setActiveFieldName("landlord");
              setShowCreateCompanyDialog(true);
            }}
          >
            <CompanyAutoselectMui name="landlord" label="Landlord" />
          </FieldWithAddButton>
        </Grid>

        <Grid item xs={12}>
          <MultipleAutocompleteMui options={tagOptions} loading={false} multiple name="tags" label="Tags" />
        </Grid>

        <Grid item xs={12}>
          <TextFieldMui name="notes" label="Notes" multiline rows={4} fullWidth />
        </Grid>
      </Grid>

      <CreateCompanyDialogForm
        open={showCreateCompanyDialog}
        onClose={() => {
          setShowCreateCompanyDialog(false);
          setInitialRole(null);
        }}
        onSubmit={(companyValues) => {
          setCompanyToCreate(companyValues);
          setShowCreateCompanyDialog(false);
          setCreateCompanyVerificationDialogIsOpen(true);
        }}
        initialValues={{markets: [], company_role_id: initialRole}}
      />
      <ConfirmationDialog
        isOpen={createCompanyVerificationDialogIsOpen}
        onApprove={() => {
          setCreateCompanyVerificationDialogIsOpen(false);
          blockUI.blockUI("Creating company...");
          axiosAPI.post("/companies/", companyToCreate).then((res) => {
            form.change(activeFieldName, res.data);
            blockUI.unblockUI();
          });
        }}
        onDeny={() => setCreateCompanyVerificationDialogIsOpen(false)}
      >
        You want to create new company {companyToCreate?.name}. Please make sure this company does not already exist to
        avoid duplicate companies.
      </ConfirmationDialog>
    </>
  );
};

const FieldWithAddButton = (props: {children: any; showButton?: boolean; onClick: () => void; [rest: string]: any}) => {
  const {children, showButton = true, onClick, ...rest} = props;

  return (
    <>
      <Box display="flex">
        <Box flexGrow={1}>{children}</Box>
        {showButton && (
          <Tooltip title="Create New Company">
            <Box ml={1} mt={1}>
              <FontAwesomeIcon icon={faPlusCircle} color={colorSuccess} onClick={onClick} {...rest} />
            </Box>
          </Tooltip>
        )}
      </Box>
    </>
  );
};

export const RFPOnboardingForm = (props: {
  onSubmit: (values: any) => void;
  isFetching: boolean;
  tagOptions: string[];
  isNew?: boolean;
  [rest: string]: any;
}) => {
  const {onSubmit, isFetching, tagOptions = [], isNew, ...rest} = props;

  return (
    <>
      <FinalForm onSubmit={onSubmit} keepDirtyOnReinitialize {...rest}>
        {({handleSubmit, form, submitting, pristine, values}) => {
          return (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                <RFPOnboardingCommonFields
                  form={form}
                  isFetching={isFetching}
                  tagOptions={tagOptions}
                  isNew={isNew}
                  isTracked={values.is_tracked}
                />
                <InnerFooter>
                  <Box display="flex">
                    <FormActions.SaveButton disabled={submitting || pristine} label={isNew ? `Create` : `Save`} />
                    <Box ml={1} />
                  </Box>
                </InnerFooter>
              </FinalFormKeyboardShortcuts>
            </form>
          );
        }}
      </FinalForm>
    </>
  );
};

export const RFPOnboardingDialogForm = (props: {
  onSubmit: (values: any) => void;
  open: boolean;
  onClose: () => void;
  isNew?: boolean;
  [rest: string]: any;
}) => {
  const {onSubmit, open, onClose, isNew, ...rest} = props;
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{isNew ? "New" : "Edit"} Request For Proposal</DialogTitle>

      <FinalForm onSubmit={onSubmit} {...rest}>
        {({handleSubmit, form, submitting, pristine, values}) => (
          <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
            <DialogContent>
              <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                <RFPOnboardingCommonFields form={form} />
              </FinalFormKeyboardShortcuts>
            </DialogContent>
            <DialogActions>
              <FormActions.SaveButton disabled={submitting || pristine} />
              <FormActions.CancelButton onClick={onClose} />
            </DialogActions>
          </form>
        )}
      </FinalForm>
    </Dialog>
  );
};

export const RFPNotesQuickEditDialog = (props: {
  onSubmit: (values: any) => void;
  open: boolean;
  onClose: () => void;
  isNew?: boolean;
  [rest: string]: any;
}) => {
  const {onSubmit, open, onClose, isNew, ...rest} = props;
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Quick Edit</DialogTitle>

      <FinalForm onSubmit={onSubmit} {...rest}>
        {({handleSubmit, form, submitting, pristine, values}) => (
          <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
            <DialogContent>
              <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextFieldMui name="notes" label="Notes" multiline rows={4} fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldMui
                      name="interview_prep_status"
                      label="Interview/Prep Status"
                      multiline
                      rows={4}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldMui name="result" label="Result" multiline rows={4} fullWidth />
                  </Grid>
                </Grid>
              </FinalFormKeyboardShortcuts>
            </DialogContent>
            <DialogActions>
              <FormActions.SaveButton disabled={submitting || pristine} />
              <FormActions.CancelButton onClick={onClose} />
            </DialogActions>
          </form>
        )}
      </FinalForm>
    </Dialog>
  );
};

export const RFPCompetitorBidDialogForm = (props: {
  onSubmit: (values: any) => void;
  open: boolean;
  onClose: () => void;
  isNew?: boolean;
  [rest: string]: any;
}) => {
  const {onSubmit, open, onClose, isNew, ...rest} = props;
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{isNew ? "Create" : "Edit"} Competitor Info</DialogTitle>

      <FinalForm onSubmit={onSubmit} {...rest}>
        {({handleSubmit, form, submitting, pristine, values}) => (
          <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
            <DialogContent>
              <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3}>
                    <CompanyAutoselectMui name="company" label="Company" required autoFocus />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <CurrencyFieldMui name="weekly_gcs" label="Weekly GCs" />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <PercentFieldMui name="insurance_rate" label="Insurance Rate" />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <PercentFieldMui name="fee" label="Fee" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldMui name="notes" label="Notes" multiline rows={4} fullWidth />
                  </Grid>
                </Grid>
              </FinalFormKeyboardShortcuts>
            </DialogContent>
            <DialogActions>
              <FormActions.SaveButton disabled={submitting || pristine} />
              <FormActions.CancelButton onClick={onClose} />
            </DialogActions>
          </form>
        )}
      </FinalForm>
    </Dialog>
  );
};

export const RFPWizardOnboardingFields = (props) => {
  const {form, values, tagOptions} = props;

  return <RFPOnboardingCommonFields isTracked={values?.is_tracked} tagOptions={tagOptions} form={form} isNew />;
};

export const RFPWizardCompetitorFields = (props) => {
  const {form, values, useWizard} = props;

  const addCompetitorCallback = React.useRef(() => {});

  const [showCreateCompanyDialog, setShowCreateCompanyDialog] = React.useState(false);
  const [createCompanyVerificationDialogIsOpen, setCreateCompanyVerificationDialogIsOpen] = React.useState(false);
  const [companyToCreate, setCompanyToCreate] = React.useState(null);
  const [targetCompanyIndex, setTargetCompanyIndex] = React.useState(0);

  const blockUI = useBlockUI();
  return (
    <>
      <Grid container spacing={2}>
        <FieldArray name={`competitor_bids`}>
          {({fields}) => {
            addCompetitorCallback.current = () => fields.push(undefined);
            return (
              <>
                {fields.map((name, i) => {
                  return (
                    <React.Fragment key={name}>
                      {i !== 0 && <Box mt={1} />}
                      <LabeledGroup label={`# ${i + 1}`} pt={1}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <FieldWithAddButton
                              setActiveFieldName={() => {
                                `${name}.company`;
                              }}
                              onClick={() => {
                                setTargetCompanyIndex(i);
                                setShowCreateCompanyDialog(true);
                              }}
                            >
                              <CompanyAutoselectMui name={`${name}.company`} label="Company" autoFocus required />
                            </FieldWithAddButton>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <CurrencyFieldMui name={`${name}.weekly_gcs`} label="Weekly GCs" />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <PercentFieldMui name={`${name}.insurance_rate`} label="Insurance Rate" />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <PercentFieldMui name={`${name}.fee`} label="Fee" />
                          </Grid>
                          <Grid item xs={12}>
                            <TextFieldMui name={`${name}.notes`} label="Notes" multiline rows={4} fullWidth />
                          </Grid>
                          <Grid item xs={12}>
                            <Box textAlign={"right"}>
                              <IconButton
                                aria-label="delete"
                                size="small"
                                onClick={() => fields.remove(i)}
                                // disabled={values.competitors.length < 2}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </Grid>
                        </Grid>
                      </LabeledGroup>
                    </React.Fragment>
                  );
                })}
              </>
            );
          }}
        </FieldArray>
        <Grid item xs={12}>
          <Box textAlign={"right"}>
            <Button onClick={() => addCompetitorCallback.current()} variant="contained">
              Add Competitor
            </Button>
          </Box>
        </Grid>
      </Grid>
      <CreateCompanyDialogForm
        open={showCreateCompanyDialog}
        onClose={() => {
          setShowCreateCompanyDialog(false);
        }}
        onSubmit={(companyValues) => {
          setCompanyToCreate(companyValues);
          setShowCreateCompanyDialog(false);
          setCreateCompanyVerificationDialogIsOpen(true);
        }}
        initialValues={{markets: []}}
      />
      <ConfirmationDialog
        isOpen={createCompanyVerificationDialogIsOpen}
        onApprove={() => {
          setCreateCompanyVerificationDialogIsOpen(false);
          blockUI.blockUI("Creating company...");
          axiosAPI
            .post("/companies/", companyToCreate)
            .then((res) => {
              const competitorBids = form.getFieldState("competitor_bids").value;
              const competitorArray = [...competitorBids];
              competitorArray[targetCompanyIndex] = {company: {...res.data}};
              form.change("competitor_bids", competitorArray);
              blockUI.unblockUI();
            })
            .catch(() => {
              blockUI.unblockUI();
            });
        }}
        onDeny={() => setCreateCompanyVerificationDialogIsOpen(false)}
      >
        You want to create new company <strong>{companyToCreate?.name}</strong>. Please make sure this company does not
        already exist to avoid duplicate companies.
      </ConfirmationDialog>
    </>
  );
};

export const RFPWizardBoxFields = (props) => {
  const {form, values, useWizard, rfp} = props;
  const {setPreviousButtonDisabled, setSubmitLabel} = useWizard();
  React.useEffect(() => {
    setPreviousButtonDisabled(true);
    setSubmitLabel("Complete");
  }, []);

  return (
    <>
      <DropzoneMui
        getUploadParams={() => {
          return {
            url: `/v2/marketing/rfps/onboarding/?rfp_id=${rfp.id}`,
            headers: {"X-CSRFToken": Cookies.get("csrftoken")},
          };
        }}
        timeout={1000 * 60 * 5}
        accept="*"
        // onChangeStatus={(event) => {
        //   if (event.meta.status === "rejected_file_type") {
        //     enqueueSnackbar("You must upload a PDF", {variant: "error"});
        //   }
        //   if (event.meta.status === "uploading") {
        //     blockUI.blockUI("Uploading...");
        //   }

        //   if (event.meta.status === "done") {
        //     blockUI.unblockUI();
        //     window.location.reload();
        //   }
        // }}
      />
    </>
  );
};

export const RFPImportValidationForm = (props) => {
  const {rfp, checked, onChangeChecked, onClickValidate} = props;
  return (
    <FinalForm {...props}>
      {({handleSubmit, form, submitting, pristine, values, hasValidationErrors}) => (
        <form onSubmit={handleSubmit}>
          <AutoSubmitSection
            form={form}
            title={
              <Box display="flex" alignItems="center">
                <PaperItem.SelectedCheckbox checked={checked} onChange={onChangeChecked} />
                <Box mr={0.5} component="span" display="flex">
                  <StatusLabel status={rfp.status_display} hint="rfp" />
                </Box>
                <Box component="span" display="flex">
                  <Typography variant="h6" component="span">
                    {rfp.project_name}
                  </Typography>
                </Box>
              </Box>
            }
          >
            <Box mr={1} component="span">
              <strong>{rfp.office_display}</strong>
            </Box>
            <Box mr={1} component="span">
              {rfp.onboarded_date}
            </Box>
            <Tooltip
              title={
                <>
                  <span>Imported Data:</span>
                  <ul>
                    {Object.entries(rfp.dumped_data)
                      .filter(([key, value]) => value !== "null" && value)
                      .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                      .map(([key, value]) => (
                        <li key={key}>{`${key}: ${value}`}</li>
                      ))}
                  </ul>
                </>
              }
            >
              <FontAwesomeIcon icon={faCircleInfo} color={colorInfo} />
            </Tooltip>
            <Box mr={1} component="span" />
            <Tooltip title="Validate">
              <FontAwesomeIcon icon={faCheck} onClick={onClickValidate} />
            </Tooltip>
          </AutoSubmitSection>
          <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <ProjectAutoselectMui name="project" label="Project" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CompanyAutoselectMui
                  name="architect"
                  label="Architect"
                  helperText={
                    rfp.dumped_data.Architect ? `Imported As: ${rfp.dumped_data.Architect}` : "Nothing Imported"
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CompanyAutoselectMui
                  name="construction_manager"
                  label="Construction Manager"
                  helperText={
                    rfp.dumped_data["Owner's Rep or CM"]
                      ? `Imported As: ${rfp.dumped_data["Owner's Rep or CM"]}`
                      : "Nothing Imported"
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CompanyContactAutoselectMui
                  name="project_executive"
                  label="Project Executive"
                  companyId={1547}
                  helperText={
                    rfp.dumped_data["Project Executive"] || rfp.dumped_data["PE/APM"]
                      ? `Imported As: ${rfp.dumped_data["Project Executive"] || rfp.dumped_data["PE/APM"]}`
                      : "Nothing Imported"
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CompanyContactAutoselectMui
                  name="project_manager"
                  label="Project Manager"
                  companyId={1547}
                  helperText={
                    rfp.dumped_data.PM || rfp.dumped_data.SPM
                      ? `Imported As: ${rfp.dumped_data.PM || rfp.dumped_data.SPM}`
                      : "Nothing Imported"
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CompanyContactAutoselectMui
                  name="project_engineer"
                  label="Project Engineer"
                  companyId={1547}
                  helperText={
                    rfp.dumped_data.Architect ? `Imported As: ${rfp.dumped_data.Architect}` : "Nothing Imported"
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CompanyContactAutoselectMui
                  name="superintendent"
                  label="Superintendent"
                  companyId={1547}
                  helperText={rfp.dumped_data.Super ? `Imported As: ${rfp.dumped_data.Super}` : "Nothing Imported"}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldMui name="notes" label="Notes" multiline rows={4} fullWidth />
              </Grid>
            </Grid>
          </FinalFormKeyboardShortcuts>
          <ActiveFieldsFormSpy
            onFocusChange={({active}) => {
              const ignoreFields = [""];
              if (active === undefined && !pristine && !hasValidationErrors && !ignoreFields.includes(active)) {
                handleSubmit();
              }
            }}
          />
        </form>
      )}
    </FinalForm>
  );
};

export const RFPBulkEditDialogForm = (props: {
  onSubmit: (values: any) => void;
  open: boolean;
  onClose: () => void;
  selectedCount: number;
  [rest: string]: any;
}) => {
  const {onSubmit, open, onClose, isNew, selectedCount = 0, ...rest} = props;
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Bulk Edit {selectedCount} Imported RFPs</DialogTitle>

      <FinalForm onSubmit={onSubmit} {...rest}>
        {({handleSubmit, form, submitting, pristine, values}) => (
          <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
            <DialogContent>
              <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <CompanyAutoselectMui name="architect" label="Architect" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CompanyAutoselectMui name="construction_manager" label="Construction Manager" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CompanyContactAutoselectMui name="project_executive" label="Project Executive" companyId={1547} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CompanyContactAutoselectMui name="project_manager" label="Project Manager" companyId={1547} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CompanyContactAutoselectMui name="project_engineer" label="Project Engineer" companyId={1547} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CompanyContactAutoselectMui name="superintendent" label="Superintendent" companyId={1547} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldMui name="notes" label="Notes" multiline rows={4} fullWidth />
                  </Grid>
                </Grid>
              </FinalFormKeyboardShortcuts>
            </DialogContent>
            <DialogActions>
              <FormActions.SaveButton disabled={submitting || pristine} />
              <FormActions.CancelButton onClick={onClose} />
            </DialogActions>
          </form>
        )}
      </FinalForm>
    </Dialog>
  );
};

const CreateContactDialogForm = (props: {
  onSubmit: (values: any) => void;
  open: boolean;
  onClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, open, onClose, ...rest} = props;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Create Contact</DialogTitle>
      <DialogContent>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            const validateEmail = async (value) => {
              if (!value) {
                return "Email is required";
              }
              if (!validateEmailAddress(value)) {
                return "Not a valid email address";
              }
              const response = await axiosAPI.get(`/contacts/validate-email/?email=${value}`);
              if (response.data.error) {
                return response.data.error;
              }
            };
            const selectedContactRole = find(contactRoles, (role) => values.contact_role?.id === role.value);
            return (
              <>
                <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <PaperPanel.Body>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextFieldMui
                            name="email"
                            label="Email"
                            type="email"
                            autoFocus
                            required
                            fieldProps={{
                              parse: identity,
                              validate: validateEmail,
                            }}
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <TextFieldMui name="first_name" label="First Name" required />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <TextFieldMui name="last_name" label="Last Name" required />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <TextFieldMui name="title" label="Title" />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <GroupedSimpleSelect
                            allowNull
                            name="contact_role.id"
                            label="Role"
                            options={contactRoles}
                            helperText={
                              selectedContactRole ? `${selectedContactRole.group} - ${selectedContactRole.label}` : ""
                            }
                          />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <CompanyAutoselectMui required name="company" label="Company" />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <MaskedInputMui name="phone" label="Phone" mask="(999)-999-9999" />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <TextFieldMui name="extension" label="Extension" />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <SimpleSelect name="type" label="Type" options={phoneNumberTypes} />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldMui name="notes" label="Notes" multiline minRows={2} />
                        </Grid>
                      </Grid>
                    </PaperPanel.Body>
                    <DialogActions>
                      <FormActions.CancelButton onClick={onClose} />
                      <FormActions.SaveButton disabled={submitting || pristine} />
                    </DialogActions>
                  </FinalFormKeyboardShortcuts>
                </form>
              </>
            );
          }}
        </FinalForm>
      </DialogContent>
    </Dialog>
  );
};

const CreateCompanyDialogForm = (props: {
  onSubmit: (values: any) => void;
  open: boolean;
  onClose: () => void;
  [rest: string]: any;
}) => {
  const {open, onClose, onSubmit, ...rest} = props;
  const permissions = usePermissions();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Create Company</DialogTitle>
      <DialogContent>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            return (
              <>
                <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <PaperPanel.Body>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextFieldMui name="name" label="Name" required autoFocus />
                        </Grid>
                        <Grid item xs={12}>
                          <CSVTagsMui
                            name="nicknames"
                            label="Nicknames"
                            helperText={`These names will be included when searching`}
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <TextFieldMui
                            name="email_domain"
                            label="Email Domain"
                            helperText={
                              <>
                                Example: novoconstruction.com <i>(used to link contacts to companies)</i>
                              </>
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <SimpleAutoselectMui
                            name="company_role_id"
                            label="Company Role"
                            options={companyRoles}
                            required
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <SimpleAutoselectMui
                            name="primary_csi_code"
                            label="Primary CSI Code"
                            options={csiCodesNovo}
                          />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                          <FinalSwitches name="is_union" data={{label: "Is Union", value: true}} />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                          <FinalSwitches
                            name="is_subcontract_blocklisted"
                            data={{label: "Subcontract Blocklisted", value: true}}
                            disabled={!permissions.is_executive}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <AutoselectMui
                            name="markets"
                            label="Markets"
                            valueProp="id"
                            labelProp="description"
                            multiple
                            options={offices.map((option) => {
                              return {
                                id: option.value,
                                description: option.label,
                              };
                            })}
                          />
                        </Grid>
                      </Grid>
                    </PaperPanel.Body>
                    <DialogActions>
                      <FormActions.CancelButton onClick={onClose} />
                      <FormActions.CreateButton disabled={submitting || pristine} />
                    </DialogActions>
                  </FinalFormKeyboardShortcuts>
                </form>
              </>
            );
          }}
        </FinalForm>
      </DialogContent>
    </Dialog>
  );
};
