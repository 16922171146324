import arrayMove from "array-move";
import {Method} from "axios";
import {useSnackbar} from "notistack";
import React from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useLocation} from "react-router";
import {axiosAPI} from "../api";
import {truncateString} from "../utils/string";
import qs from "query-string";

export const getAxiosAPIResponseMessage = (error) => {
  const errorMessage = error?.response?.data?.detail || error?.response?.data || error.message;
  if (typeof errorMessage === "string") {
    return truncateString(errorMessage, 100);
  }
  return <pre>{JSON.stringify(errorMessage, null, 2)}</pre>;
};

export const useSentinelDetailAPI = (
  queryKey,
  useQueryConfig = {},
  additionalProps: {resetOnUpdateError?: boolean} = {}
) => {
  additionalProps = {resetOnUpdateError: true, ...additionalProps};
  const {enqueueSnackbar} = useSnackbar();
  const queryClient = useQueryClient();
  let url = queryKey;
  if (Array.isArray(queryKey)) {
    url = `/${queryKey.join("/")}/`;
  }
  const _baseURL = url.split("?")[0];

  const query = useQuery(queryKey, () => axiosAPI.get(url).then((res) => res.data), {
    retry: false,
    refetchOnWindowFocus: false,
    onError: (error) => {
      enqueueSnackbar(getAxiosAPIResponseMessage(error), {variant: "error"});
    },
    ...useQueryConfig,
  }) as any;
  const create = useMutation((newObject) => axiosAPI.post(url, newObject).then((res) => res.data), {
    onMutate: (newData) => {
      const previousValue = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, newData);
      return previousValue;
    },
    onSuccess: (newData) => {
      queryClient.invalidateQueries(queryKey);
    },
    onError: (error, variables, previousValue) => {
      enqueueSnackbar(getAxiosAPIResponseMessage(error), {variant: "error"});
      queryClient.setQueryData(queryKey, previousValue);
    },
  }) as any;
  const update = useMutation((newObject) => axiosAPI.patch(url, newObject).then((res) => res.data), {
    onMutate: (newData) => {
      const previousValue = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, newData);
      return previousValue;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
    onError: (error, variables, previousValue) => {
      enqueueSnackbar(getAxiosAPIResponseMessage(error), {variant: "error"});
      if (additionalProps.resetOnUpdateError) {
        queryClient.setQueryData(queryKey, previousValue);
      }
    },
  }) as any;
  const delete_ = useMutation(() => axiosAPI.delete(url).then((res) => res.data), {
    onMutate: () => {
      const previousValue = queryClient.getQueryData(queryKey);
      // queryClient.setQueryData(queryKey, null);
      return previousValue;
    },
    onSuccess: () => {
      // queryClient.invalidateQueries(queryKey);
    },
    onError: (error, variables, previousValue) => {
      enqueueSnackbar(getAxiosAPIResponseMessage(error), {variant: "error"});
      queryClient.setQueryData(queryKey, previousValue);
    },
  }) as any;
  const rpc = useMutation(
    (options: {action: string; data?: any; method?: Method; baseURL?: string}) => {
      const {action, data = {}, method = "POST", baseURL = _baseURL} = options;
      let url_ = `${baseURL}${action}/`;
      // return axiosAPI.post(url, newObject).then((res) => res.data);
      return axiosAPI({
        url: url_,
        method: method,
        data: data,
      }).then((res) => res.data);
    },
    {
      onMutate: (newData) => {
        // queryCache.setQueryData(queryKey, newData);
      },
      onSuccess: (newData) => {
        // queryCache.setQueryData(queryKey, newData);
      },
      onError: (error) => {
        enqueueSnackbar(getAxiosAPIResponseMessage(error), {variant: "error"});
      },
    }
  ) as any;

  return {
    query,
    create,
    update,
    delete: delete_,
    rpc,
  };
};

export const useSentinelListAPI = (
  queryKey,
  useQueryConfig = {},
  additionalProps: {
    idProp?: string;
    disableOnSuccess?: boolean;
    disableOnMutate?: boolean;
    insertionIndex?: number;
  } = {}
) => {
  additionalProps = {
    idProp: "id",
    disableOnSuccess: false,
    disableOnMutate: false,
    insertionIndex: null,
    ...additionalProps,
  };
  const idProp = additionalProps.idProp;

  const {enqueueSnackbar} = useSnackbar();
  const queryClient = useQueryClient();
  let url = queryKey;
  if (Array.isArray(queryKey)) {
    url = `/${queryKey.join("/")}/`;
  }
  const _baseURL = url.split("?")[0];

  const query = useQuery(queryKey, () => axiosAPI.get(url).then((res) => res.data), {
    // initialData: [],
    retry: false,
    refetchOnWindowFocus: false,
    onError: (error) => {
      enqueueSnackbar(getAxiosAPIResponseMessage(error), {variant: "error"});
    },
    ...useQueryConfig,
  }) as any;

  const create = useMutation((newObject) => axiosAPI.post(url, newObject).then((res) => res.data), {
    onMutate: (newData) => {
      const previousValue: {results: any[]} = queryClient.getQueryData(queryKey);
      // let newQueryData = {...previousValue};
      // newQueryData.results.push(newData);
      // queryClient.setQueryData(queryKey, newQueryData);
      return previousValue;
    },
    onSuccess: (newObject, oldObject, previousData: {results: any[]}) => {
      let newQueryData = {...previousData};
      if (typeof additionalProps.insertionIndex === "number") {
        newQueryData.results.splice(additionalProps.insertionIndex, 0, newObject);
      } else {
        newQueryData.results.push(newObject);
      }
      queryClient.setQueryData(queryKey, newQueryData);
    },
    onError: (error, variables, previousValue) => {
      enqueueSnackbar(getAxiosAPIResponseMessage(error), {variant: "error"});
      queryClient.setQueryData(queryKey, previousValue);
    },
  }) as any;
  const update = useMutation(
    (newObject: {id: number}) => {
      return axiosAPI.patch(`${_baseURL}${newObject[idProp]}/`, newObject).then((res) => res.data);
    },
    {
      onMutate: (newData) => {
        if (additionalProps.disableOnMutate) return;
        const previousValue: {results: any[]} = queryClient.getQueryData(queryKey);
        let newQueryData = {...previousValue};
        newQueryData.results = previousValue.results.map((obj) =>
          newData[idProp] == obj[idProp] ? {...obj, ...newData} : obj
        );
        queryClient.setQueryData(queryKey, newQueryData);
        return previousValue;
      },
      onSuccess: (newObject, oldObject, previousData: {results: any[]}) => {
        if (additionalProps.disableOnSuccess) return;
        let newQueryData = {...previousData};
        newQueryData.results = previousData.results.map((obj) => (newObject[idProp] == obj[idProp] ? newObject : obj));
        queryClient.setQueryData(queryKey, newQueryData);
      },
      onError: (error, variables, previousValue) => {
        enqueueSnackbar(getAxiosAPIResponseMessage(error), {variant: "error"});
        queryClient.setQueryData(queryKey, previousValue);
      },
    }
  ) as any;
  const delete_ = useMutation((id: number) => axiosAPI.delete(`${_baseURL}${id}/`).then((res) => res.data), {
    onMutate: (id) => {
      const previousValue: {results: any[]} = queryClient.getQueryData(queryKey);
      let newQueryData = {...previousValue};
      newQueryData.results = previousValue.results.filter((obj) => id !== obj[idProp]);
      queryClient.setQueryData(queryKey, newQueryData);
      return previousValue;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
    onError: (error, variables, previousValue) => {
      enqueueSnackbar(getAxiosAPIResponseMessage(error), {variant: "error"});
      queryClient.setQueryData(queryKey, previousValue);
    },
  }) as any;

  const onDragEnd = (result, positionProp = "position") => {
    if (!result.destination) {
      return Promise.reject("No drop destination found");
    }
    if (result.source.index === result.destination.index) {
      return Promise.reject("Dropped in same location");
    }
    const previousValue: {results: any[]; current_page_number: number; page_size: number} =
      queryClient.getQueryData(queryKey);
    const movedItem = previousValue.results[result.source.index];
    const pageOffset = (previousValue.current_page_number - 1) * previousValue.page_size || 0;

    const position = result.destination.index + pageOffset;
    movedItem[positionProp] = position;
    let newQueryData = {...previousValue};
    const reorderedResults = arrayMove(newQueryData.results, result.source.index, result.destination.index).map(
      (item, index) => {
        item[positionProp] = index + pageOffset;
        return item;
      }
    );
    newQueryData.results = reorderedResults;
    queryClient.setQueryData(queryKey, newQueryData);
    // update.mutateAsync(movedItem).then(() => queryClient.invalidateQueries(queryKey));
    return update.mutateAsync(movedItem);
  };

  const rpc = useMutation(
    (options: {
      action: string;
      id?: number | string;
      data?: any;
      method?: Method;
      baseURL?: string;
      qsParams?: any;
    }) => {
      const {action, id, data = {}, method = "POST", baseURL = _baseURL, qsParams = {}} = options;
      let url_ = `${baseURL}${action}/?${qs.stringify(qsParams)}`;
      if (id) {
        url_ = `${baseURL}${id}/${action}/?${qs.stringify(qsParams)}`;
      }

      return axiosAPI({
        url: url_,
        method: method,
        data: data,
      }).then((res) => res.data);
    },
    {
      onMutate: (newData) => {
        // queryCache.setQueryData(queryKey, newData);
      },
      onSuccess: (newData) => {
        queryClient.invalidateQueries(queryKey);
      },
      onError: (error) => {
        enqueueSnackbar(getAxiosAPIResponseMessage(error), {variant: "error"});
      },
    }
  ) as any;

  return {
    query,
    create,
    update,
    // update: React.useCallback(update, []),
    delete: delete_,
    onDragEnd: React.useCallback(onDragEnd, []),
    rpc,
    queryClient,
  };
};

export const useFetchCurrentPage = (queryConfig = {}, qsParams = {}) => {
  const {pathname} = useLocation();
  const {enqueueSnackbar} = useSnackbar();
  const url = `${pathname}?type=ajax&${qs.stringify(qsParams)}`;
  return useQuery(url, () => axiosAPI.get(url, {baseURL: ""}).then((res) => res.data), {
    retry: false,
    refetchOnWindowFocus: false,
    useErrorBoundary: true,
    onError: (error) => {
      enqueueSnackbar(getAxiosAPIResponseMessage(error), {variant: "error"});
    },
    ...queryConfig,
  }) as any;
};

export const usePostCurrentPage = (mutateConfig = {}, axiosConfig: any = {baseURL: ""}, qsParams = {}) => {
  const {pathname} = useLocation();
  const {enqueueSnackbar} = useSnackbar();
  const url = `${pathname}?${qs.stringify(qsParams)}`;
  return useMutation((values: any) => axiosAPI.post(url, values, axiosConfig).then((res) => res.data), {
    onError: (error) => {
      enqueueSnackbar(getAxiosAPIResponseMessage(error), {variant: "error"});
    },
    ...mutateConfig,
  }) as any;
};

export const useFetchAJAXPage = (url, queryConfig = {}, qsParams = {}) => {
  const {enqueueSnackbar} = useSnackbar();
  url = `${url}?type=ajax&${qs.stringify(qsParams)}`;
  return useQuery(url, () => axiosAPI.get(url, {baseURL: ""}).then((res) => res.data), {
    retry: false,
    refetchOnWindowFocus: false,
    useErrorBoundary: true,
    onError: (error) => {
      enqueueSnackbar(getAxiosAPIResponseMessage(error), {variant: "error"});
    },
    ...queryConfig,
  }) as any;
};
